import React from "react";
import "./logindetails.scoped.css";
export default function SendLoginDetails({ show, setShow }) {
  const shareDetails = (client) => {
    let url = `https://api.whatsapp.com/send?text=ID:${
      show?.username
    }\n PW:${show?.password}\n LINK:${
      show?.userType?.toLowerCase() == "client"
        ? `\n${process.env?.REACT_APP_NAME}.com`
        : `\n${process.env?.REACT_APP_NAME}.com/admin`
    }`;
    window.open(url, "_blank");
  };
  return (
    <div
      _ngcontent-ng-c420556888=""
      id="detailaModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      className="sitemoadal modal fade show"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        _ngcontent-ng-c420556888=""
        className="modal-dialog modal-dialog-centered"
      >
        <div _ngcontent-ng-c420556888="" className="modal-content">
          <div _ngcontent-ng-c420556888="" className="modal-header">
            <h5
              _ngcontent-ng-c420556888=""
              id="exampleModalLabel"
              className="modal-title"
            >
              Reset Password
            </h5>
            <button
              _ngcontent-ng-c420556888=""
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(null);
              }}
              className="btn-close"
            />
          </div>
          <div _ngcontent-ng-c420556888="" className="modal-body">
            <div _ngcontent-ng-c420556888="" className="bet-slip-box">
              <div _ngcontent-ng-c420556888="" className="bet-slip">
                <textarea
                  rows={7}
                  readOnly=""
                  className="ant-input"
                  defaultValue={` New Password\n  LINK : ${
                    show?.userType?.toLowerCase() == "client"
                      ? `${process.env?.REACT_APP_NAME}.com`
                      : `${process.env?.REACT_APP_NAME}.com/admin`
                  }\n  ID : ${show?.username}\n  PW : ${
                    show?.password
                  }\n  \n  `}
                />
              </div>

              <div
                _ngcontent-ng-c420556888=""
                className="place-bet-btn mt-4 text-align"
              >
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `New Password\n  LINK : ${
                        window.location.hostname
                      }\n  ID : ${show?.username}\n  PW : ${
                        show?.password
                      }\n  \n  `
                    );
                    shareDetails(show);
                    setShow(null);
                  }}
                  _ngcontent-ng-c420556888=""
                  className="btn btn-primary btn-block"
                >
                  <span _ngcontent-ng-c420556888="">Share on WhatsApp</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="ant-modal-root snipcss-UKMQw">
      <div className="ant-modal-mask" />
      <div tabIndex={-1} className="ant-modal-wrap">
        <div
          role="dialog"
          aria-labelledby="rc_unique_1"
          aria-modal="true"
          className="ant-modal gx-px-3 style-1GDWy"
          id="style-1GDWy"
        >
          <div
            tabIndex={0}
            aria-hidden="true"
            id="style-Zo9RE"
            className="style-Zo9RE"
          />
          <div className="ant-modal-content">
            <button
              type="button"
              aria-label="Close"
              onClick={() => {
                setShow(null);
              }}
              className="ant-modal-close"
            >
              <span className="ant-modal-close-x">
                <span
                  role="img"
                  aria-label="close"
                  className="anticon anticon-close ant-modal-close-icon"
                >
                  <svg
                    fillRule="evenodd"
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                  </svg>
                </span>
              </span>
            </button>
            <div className="ant-modal-header">
              <div className="ant-modal-title" id="rc_unique_1">
                Reset Password
              </div>
            </div>
            <div className="ant-modal-body">
              <textarea
                rows={7}
                readOnly=""
                className="ant-input"
                defaultValue={` New Password\n  LINK : ${
                  show?.userType?.toLowerCase() == "client"
                    ? `${process.env?.REACT_APP_NAME}.com`
                    : `${process.env?.REACT_APP_NAME}.com/admin`
                }\n  ID : ${show?.username}\n  PW : ${
                  show?.password
                }\n  \n  `}
              />
            </div>
            <div className="ant-modal-footer">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `New Password\n  LINK : ${
                      window.location.hostname
                    }\n  ID : ${show?.username}\n  PW : ${
                      show?.password
                    }\n  \n  `
                  );
                  shareDetails(show);
                  setShow(null);
                }}
                className="ant-btn ant-btn-primary gx-pointer"
              >
                <span>Share on WhatsApp</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  setShow(null);
                }}
                className="ant-btn ant-btn-default gx-bg-grey gx-text-light-grey gx-pointer"
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
          <div
            tabIndex={0}
            aria-hidden="true"
            id="style-s9lB4"
            className="style-s9lB4"
          />
        </div>
      </div>
    </div>
  );
}
